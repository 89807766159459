<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form
            ref="form">
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  sm8
                  md4>
                  <v-autocomplete
                    v-model="category.id"
                    :items="category.list"
                    :clearable="true"
                    :label="$t('voucherProduct.category')"
                  />
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="getVoucherProduct()"
              >
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          :title="$t('voucherProduct.productListTitle')"
          color="green"
          flat
          full-width
        >
          <template>
            <v-btn
              color="success"
              dark
              style="float: right;"
              @click="onShowModalUpdateVoucherProductInfo(actionVoucherProductType.PUBLIC_ON_PORTAL, null)"
            >
              {{ $t("voucherProduct.putOnSalesChannel") }}
            </v-btn>
            <v-btn
              color="warning"
              dark
              style="float: right;"
              @click="onShowModalUpdateVoucherProductInfo(actionVoucherProductType.TAKE_DOWN_FROM_PORTAL, null)"
            >
              {{ $t("voucherProduct.takeDownFromSalesChannel") }}
            </v-btn>
          </template>
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="categoryHeaders"
            :items="productList"
            :single-select="true"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }">
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }">
              <td>{{ item.stt }}</td>
              <td class="text-xs-center">
                <img
                  :src="item.image ? item.image : noImg"
                  width="60"
                  height="40"
                  style="border: 2px solid #fff;">
              </td>
              <td>{{ item.name }}</td>
              <td>{{ item.issuanceCode }}</td>
              <td>{{ item.total }}</td>
              <td>{{ item.warehouse }}</td>
              <td>{{ item.sold }}</td>
              <td>{{ formatMoney(item.price) }}</td>
              <td>{{ formatMoney(item.pricePromo) }}</td>
              <td>{{ getCategoriesStr(item.extraInfo) }}</td>
              <td class="text-xs-center">
                <v-menu
                  v-model="item.selectedButton"
                  offset-y
                  transition="slide-y-transition"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="indigo"
                      dark
                      v-on="on">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <!-- Update supplier -->
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mx-2"
                          fab
                          small
                          color="primary"
                          style="color: #ffffff;"
                          v-on="on"
                          @click="onShowModalUpdateVoucherProductInfo(actionVoucherProductType.UPDATE_PRODUCT_INFO, item)"
                        >
                          <v-icon dark>mdi-square-edit-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("common.update") }}</span>
                    </v-tooltip>
                    <!-- Delete supplier -->
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mx-2"
                          fab
                          small
                          color="red"
                          style="color: #ffffff;"
                          v-on="on"
                          @click="onShowModalConfirmDeleteCategory(item.id)"
                        >
                          <v-icon dark>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("common.delete") }}</span>
                    </v-tooltip>
                  </v-card>
                </v-menu>
              </td>
            </template>
          </v-data-table>
          <pagination
            :total="productPaginate.totalPage"
            :current-page="productPaginate.currentPage"
            :row-per-page="productPaginate.rowPerPage"
            @onPageChange="onPageChange"
          />
        </material-card>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <confirmModal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onDeleteCategory"
    />
    <AddProductModal
      ref="addProductModal"
      @createCategorySuccess="onCreateCategorySuccess"
      @updateVoucherProductSuccess="onUpdateVoucherProductSuccess"
    />
    <loadingBar :is-loading="isLoading" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ToastType from 'enum/toastType'
import ConfirmModal from 'Components/ConfirmModal'
import functionUtils from 'utils/functionUtils'
import AddProductModal from './Add.vue'
import ActionVoucherProductType from 'enum/actionVoucherProductType'
export default {
  components: {
    ConfirmModal,
    AddProductModal
  },
  data: () => ({
    categoryHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt'
      },
      {
        sortable: false,
        text: 'common.image',
        value: 'image'
      },
      {
        sortable: false,
        text: 'common.name',
        value: 'name'
      },
      {
        sortable: false,
        text: 'evoucher.issuance.code',
        value: 'issuanceCode'
      },
      {
        sortable: false,
        text: 'voucherProduct.total',
        value: 'total'
      },
      {
        sortable: false,
        text: 'voucherProduct.warehouse',
        value: 'warehouse'
      },
      {
        sortable: false,
        text: 'voucherProduct.sold',
        value: 'sold'
      },
      {
        sortable: false,
        text: 'evoucher.issuance.price',
        value: 'price'
      },
      {
        sortable: false,
        text: 'voucherProduct.config.pricePromo',
        value: 'pricePromo'
      },
      {
        sortable: false,
        text: 'voucherProduct.category',
        value: 'categories'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        align: 'center'
      }
    ],
    productList: [],
    productPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    category: {
      id: null,
      list: []
    },
    categoryId: null,
    confirmModalTitle: null,
    isLoading: false,
    noImg: require('@/assets/noimage.png'),
    actionVoucherProductType: {
      UPDATE_PRODUCT_INFO: ActionVoucherProductType.UPDATE_PRODUCT_INFO,
      PUBLIC_ON_PORTAL: ActionVoucherProductType.PUBLIC_ON_PORTAL,
      TAKE_DOWN_FROM_PORTAL: ActionVoucherProductType.TAKE_DOWN_FROM_PORTAL
    }
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'VOUCHER_PRODUCT_LIST_DATA'
    ])
  },
  watch: {
    VOUCHER_PRODUCT_LIST_DATA () {
      let res = this.VOUCHER_PRODUCT_LIST_DATA
      // Handle paginate
      this.productPaginate.currentPage = res.paginate.currentPage
      this.productPaginate.totalPage = res.paginate.totalPage
      this.productPaginate.rowPerPage = res.paginate.perPage / 2
      // Handle response data
      let productData = res.results
      this.productList = []
      for (let i = 0, size = productData.length; i < size; i++) {
        let productObj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          // id: `${productData[i].id}-${productData[i].issuance.id}`,
          id: productData[i].id,
          name: productData[i].name,
          image: productData[i].image_url,
          warehouse: productData[i].warehouse,
          price: productData[i].value,
          extraInfo: productData[i].extra_info,
          total: productData[i].total,
          sold: productData[i].sold,
          pricePromo: this.getPromoPrice(productData[i].extra_info),
          usageSites: productData[i].usage_sites,
          description: productData[i].description,
          issuanceCode: functionUtils.concatSuffixPrefix(productData[i].issuance),
          issuanceId: productData[i].issuance.id
        }
        this.productList.push(productObj)
      }
      this.isLoading = false
    }
  },
  created () {
    this.getCategories()
    this.getVoucherProduct()
  },
  methods: {
    /**
     * Get promo price
     */
    getPromoPrice: function (extraInfo) {
      return extraInfo.product && extraInfo.product.price_promo ? extraInfo.product.price_promo : null
    },
    /**
     * Get string categories
     */
    getCategoriesStr: function (extraInfo) {
      let catetories = []
      catetories = extraInfo.supplier.categories.concat(extraInfo.product.categories)
      let supplierCategoriesStr = ''
      for (let i = 0, size = catetories.length; i < size; i++) {
        supplierCategoriesStr += catetories[i].name + (i < size - 1 ? ', ' : '')
      }
      return supplierCategoriesStr
    },
    onUpdateVoucherProductSuccess: function () {
      this.getVoucherProduct()
    },
    /**
     * Get voucher product
     */
    getVoucherProduct: function () {
      this.isLoading = true
      let filter = {
        params: {
          page: this.productPaginate.currentPage,
          categoryId: this.category.id
        }
      }
      this.GET_VOUCHER_PRODUCT_LIST(filter)
        .then(function () {})
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    /**
     * Format money
     */
    formatMoney: function (price) {
      if (functionUtils.isNull(price)) {
        return ''
      }
      return functionUtils.convertFormatNumberToMoney(price)
    },
    /**
     * Show modal add category
     */
    onShowModalUpdateVoucherProductInfo: function (actionType, productInfo) {
      if (productInfo) {
        productInfo.extraInfo.agent.album_image_urls = productInfo.extraInfo.agent.album_image_urls ? productInfo.extraInfo.agent.album_image_urls : []
      }
      this.$refs.addProductModal.onShowModal(actionType, productInfo)
    },
    /**
     * Create category success
     */
    onCreateCategorySuccess: function () {
      this.getCategories()
    },
    /**
     * Delete domain
     */
    onDeleteCategory: function () {
      this.DELETE_VOUCHER_PRODUCT_CATEGORY({ id: this.categoryId })
        .then(
          function () {
            this.ON_SHOW_TOAST({
              message: this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
            this.getCategories()
          }.bind(this)
        )
        .catch(
          function (error) {
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.ON_SHOW_TOAST({
                message: this.$t(errorText),
                styleType: ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                message: this.$t('login.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }
          }.bind(this)
        )
    },
    /**
     * Show modal confirm delete domain
     */
    onShowModalConfirmDeleteCategory: function (id) {
      this.categoryId = id
      this.confirmModalTitle = 'Bạn có chắc chắn muốn xoá danh mục này?'
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.productPaginate.currentPage = page
      this.getVoucherProduct()
    },
    /**
     * Get domain
     */
    getCategories: function () {
      this.GET_VOUCHER_PRODUCT_CATEGORIES_OF_ENTITY().then(
        function (res) {
          let data = res.data
          this.category.list = []
          for (let i = 0, size = data.length; i < size; i++) {
            let obj = {
              value: data[i].id,
              text: data[i].name
            }
            this.category.list.push(obj)
          }
        }.bind(this)
      )
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_VOUCHER_PRODUCT_CATEGORIES_OF_ENTITY',
      'DELETE_VOUCHER_PRODUCT_CATEGORY',
      'GET_VOUCHER_PRODUCT_LIST'
    ])
  }
}
</script>

<style lang="scss" scoped>
.banner-img-class {
  width: 80%;
  cursor: pointer;
}
input[type="file"] {
  display: none;
}
.remove-banner-img {
  color: #ef5350;
  cursor: pointer;
}
.remove-banner-img:hover {
  text-decoration: underline;
}
/deep/.color-review {
  width: 100%;
  height: 305px;
  max-height: 305px;
  border: 1px solid #ddd;
  border-radius: 4px;
  caret-color: auto;
  &:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
