var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"padding-top":"0"},attrs:{"grid-list-xl":"","fluid":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('material-card',{staticClass:"bg-white",attrs:{"title":_vm.$t('common.search'),"color":"green","flat":"","full-width":""}},[_c('v-form',{ref:"form"},[_c('v-container',{attrs:{"py-0":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm8":"","md4":""}},[_c('v-autocomplete',{attrs:{"items":_vm.category.list,"clearable":true,"label":_vm.$t('voucherProduct.category')},model:{value:(_vm.category.id),callback:function ($$v) {_vm.$set(_vm.category, "id", $$v)},expression:"category.id"}})],1)],1),_c('v-btn',{staticClass:"pull-right",staticStyle:{"margin-right":"0"},attrs:{"color":"success"},on:{"click":function($event){return _vm.getVoucherProduct()}}},[_c('span',{staticStyle:{"text-transform":"none"}},[_vm._v(_vm._s(_vm.$t("common.search")))])])],1)],1)],1),_c('material-card',{attrs:{"title":_vm.$t('voucherProduct.productListTitle'),"color":"green","flat":"","full-width":""}},[[_c('v-btn',{staticStyle:{"float":"right"},attrs:{"color":"success","dark":""},on:{"click":function($event){return _vm.onShowModalUpdateVoucherProductInfo(_vm.actionVoucherProductType.PUBLIC_ON_PORTAL, null)}}},[_vm._v(" "+_vm._s(_vm.$t("voucherProduct.putOnSalesChannel"))+" ")]),_c('v-btn',{staticStyle:{"float":"right"},attrs:{"color":"warning","dark":""},on:{"click":function($event){return _vm.onShowModalUpdateVoucherProductInfo(_vm.actionVoucherProductType.TAKE_DOWN_FROM_PORTAL, null)}}},[_vm._v(" "+_vm._s(_vm.$t("voucherProduct.takeDownFromSalesChannel"))+" ")])],_c('v-data-table',{attrs:{"no-data-text":_vm.$t('common.noDataAvailable'),"headers":_vm.categoryHeaders,"items":_vm.productList,"single-select":true,"hide-actions":""},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"text--darken-3 font-medium",domProps:{"textContent":_vm._s(_vm.$t(header.text))}})]}},{key:"items",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.stt))]),_c('td',{staticClass:"text-xs-center"},[_c('img',{staticStyle:{"border":"2px solid #fff"},attrs:{"src":item.image ? item.image : _vm.noImg,"width":"60","height":"40"}})]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.issuanceCode))]),_c('td',[_vm._v(_vm._s(item.total))]),_c('td',[_vm._v(_vm._s(item.warehouse))]),_c('td',[_vm._v(_vm._s(item.sold))]),_c('td',[_vm._v(_vm._s(_vm.formatMoney(item.price)))]),_c('td',[_vm._v(_vm._s(_vm.formatMoney(item.pricePromo)))]),_c('td',[_vm._v(_vm._s(_vm.getCategoriesStr(item.extraInfo)))]),_c('td',{staticClass:"text-xs-center"},[_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"indigo","dark":""}},on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true),model:{value:(item.selectedButton),callback:function ($$v) {_vm.$set(item, "selectedButton", $$v)},expression:"item.selectedButton"}},[_c('v-card',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.onShowModalUpdateVoucherProductInfo(_vm.actionVoucherProductType.UPDATE_PRODUCT_INFO, item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-square-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("common.update")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"fab":"","small":"","color":"red"},on:{"click":function($event){return _vm.onShowModalConfirmDeleteCategory(item.id)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("common.delete")))])])],1)],1)],1)]}}])}),_c('pagination',{attrs:{"total":_vm.productPaginate.totalPage,"current-page":_vm.productPaginate.currentPage,"row-per-page":_vm.productPaginate.rowPerPage},on:{"onPageChange":_vm.onPageChange}})],2)],1)],1),_vm._l((_vm.GET_SNACK_BAR),function(snackbar){return _c('snack-bar',{key:snackbar.id,attrs:{"item":snackbar}})}),_c('confirmModal',{ref:"confirmModal",attrs:{"title":_vm.confirmModalTitle},on:{"onConfirm":_vm.onDeleteCategory}}),_c('AddProductModal',{ref:"addProductModal",on:{"createCategorySuccess":_vm.onCreateCategorySuccess,"updateVoucherProductSuccess":_vm.onUpdateVoucherProductSuccess}}),_c('loadingBar',{attrs:{"is-loading":_vm.isLoading}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }