
<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="600px">
    <v-card ref="card">
      <material-card
        :title="$t('voucherProduct.takeDownQuantity')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout
              wrap
              layout>
              <!-- Customer full name -->
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  :rules="[ruleRequiredValue, ruleMustGreaterThanZero]"
                  v-model="quantityToTakeDown"
                  :label="$t('voucherProduct.quantityToTakeDown')"
                  :name="$t('voucherProduct.quantityToTakeDown')"
                  type="text"
                  class="required"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t('common.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import ToastType from 'enum/toastType'
// import stringUtils from 'utils/stringUtils'
// import dateUtils from 'utils/dateUtils'
// import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
export default {
  data: () => ({
    isShowModal: false,
    valid: false,
    quantityToTakeDown: 0,
    quantityAvailable: 0
  }),
  watch: {},
  methods: {
    ruleMustGreaterThanZero (value) {
      return validationRules.ruleMustGreaterThan(value, 0)
    },
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    /**
     * Set default data
     */
    setDefaultData: function () {
      this.quantityToTakeDown = 0
    },
    /**
     * Show modal
     */
    onShowModal: function (quantityAvailable) {
      this.isShowModal = true
      this.setDefaultData()
      this.quantityAvailable = quantityAvailable
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    /**
     * Confirm
     */
    onConfirm: function () {
      if (this.$refs.form.validate()) {
        if (this.quantityToTakeDown > this.quantityAvailable) {
          this.ON_SHOW_TOAST({
            'message': this.$t(`voucherProduct.numberOfVouchersRemovedExceedsQuantityPutUp`),
            styletype: ToastType.ERROR
          })
        } else {
          this.$emit('onConfirm', this.quantityToTakeDown)
          // this.onCloseModal()
        }
      }
    },
    ...mapActions([
      'GET_USER_INFO',
      'ON_SHOW_TOAST'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
